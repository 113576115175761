(function dateRangePickerScript($, Drupal, drupalSettings) {
  Drupal.behaviors.dateRangePicker = {
    attach(context) {
      if ($('#edit-start').length) {

        let lang = drupalSettings.path;
        if (lang === undefined) {
          lang = 'en';
        }

        $('#edit-start').periodpicker({
          end: $('#edit-end'),
          formatDate: 'YYYY-MM-DD',
          formatMonth: 'MMMM',
          formatDecoreDate: 'Do MMM',
          formatDecoreDateWithYear: 'Do MMM YY',
          animation: false,
          fullsizeButton: false,
          fullsizeOnDblClick: false,
          draggable: false,
          withoutBottomPanel: true,
          yearsLine: false,
          resizeButton: false,
          cells: [1, 2],
          title: false,
          lang: lang,
          i18n: {
            'en': {
              'Choose period': 'Any Date'
            },
            'ga': {
              'Choose period': 'Dáta ar bith'
            }
          }
        });
      }
    },
  };
}(jQuery, Drupal, window.drupalSettings));
